import React from 'react';

import {
  Chip,
} from '@material-ui/core';

import { colors, lighterColors } from '../../helpers/commonHelpers';

const StationsChips = ({ value, getTagProps }) => value.map((option, index) => (
  <Chip
    variant="outlined"
    key={option.code}
    label={option.label}
    style={{ color: colors[index], backgroundColor: lighterColors[index] }}
    {...getTagProps({ index })}
  />
));

export default StationsChips;
