import React from 'react';

import {
  TextField,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import StationsChips from './StationsChips';

const StationsListAutocomplete = ({
  stationsProp,
  selectedStations,
  onStationChange,
}) => (
  <Autocomplete
    multiple
    id="tags-standard"
    options={stationsProp}
    groupBy={station => station.sub_management_unit.label}
    getOptionLabel={station => station.label}
    getOptionSelected={(option, value) => option.code === value.code}
    value={selectedStations}
    onChange={onStationChange}
    filterSelectedOptions
    renderTags={(value, getTagProps) => (
      <StationsChips
        value={value}
        getTagProps={getTagProps}
      />
    )}
    renderInput={props => (
      <TextField
        {...props}
        variant="standard"
        label="Stations sélectionnées"
        placeholder="ajouter des stations à comparer"
      />
    )}
  />
);

export default StationsListAutocomplete;
