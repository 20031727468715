import React from 'react';

import { Typography } from '@material-ui/core';

const StationDashboardSummary = ({ properties = {}, ...props }) => (
  <Typography variant="body1" {...props}>
    {properties.is_spc && (<>Station Service Prévention Crues<br /></>)}
    Unité de gestion : {properties.management_unit ?? '-'}<br />
    Groupement : {properties.pool ?? '-'}<br />
    Département : {properties.department?.label ?? '-'}<br />
    Gestionnaire : {properties.managers ?? '-'}<br />
    Code station : {properties.code ?? '-'}<br />
    Cours d'eau : {properties.watercourse?.label ?? '-'}
  </Typography>
);

export default React.memo(StationDashboardSummary);
