import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Paper, Box, Typography } from '@material-ui/core';

import useStations from '../../hooks/useStations';
import StationChart from '../StationChart';
import StationsListAutocomplete from './StationsListAutocomplete';
import StationsQmjDayChart from './StationsQmjDayChart';
import {
  availableDataModes,
  frontAnteriorityConfigs,
} from '../../helpers/commonHelpers';
import StationDataToggleButtons from '../StationDataToggleButtons';
import CustomDatePicker from '../CustomDatePicker';
import StationsCompareDetails from './StationsCompareDetails';

const useStyles = makeStyles(theme => ({
  stdPadding: {
    padding: theme.spacing(2, 0),
  },
  chartsWrapper: {
    padding: theme.spacing(1, 2, 2),
    marginTop: theme.spacing(1),
  },
  mainPadding: {
    padding: theme.spacing(1, 1),
  },
  exportButton: {
    margin: theme.spacing(0),
    float: 'right',
  },
}));

const StationsCompare = ({ defaultStationProperties = {}, onDataModeChange = null }) => {
  const classes = useStyles();

  const selectedDate = useSelector(({ mainDate }) => mainDate);

  const stationsProp = useStations()
    .map(({ properties }) => properties)
    .sort((station1, station2) =>
      station1.sub_management_unit.order - station2.sub_management_unit.order);

  const [selectedStations, setSelectedStations] = React.useState([defaultStationProperties]);
  const onStationChange = (event, value) => setSelectedStations(value);

  const emptyStationsList = selectedStations.length === 0;
  const currentStationsCodes = selectedStations.map(({ code }) => code);

  const selectedStationsModesIds = React.useMemo(
    () => [...new Set(
      selectedStations
        .map(({ quantities }) => quantities)
        .reduce((a, b) => a.concat(b), []),
    )],
    [selectedStations],
  );

  const currentAvailableDataModeConfigs = availableDataModes(selectedStationsModesIds);

  // we cannot use useToggle here because we want to be able to modify the available
  // modes (Qi, Qmj ...) depending on the selected stations
  const defaultDataMode = selectedStationsModesIds[0];
  const [activeDataModeId, setActiveDataModeId] = React.useState(defaultDataMode);

  // Notify the parent component about the initial dataMode.
  React.useEffect(() => {
    onDataModeChange(defaultDataMode);
  }, []);

  const handleDataModeChange = (event, newValue) => {
    // when clicking on the current active button, newValue is set to null
    if (newValue != null) {
      setActiveDataModeId(newValue);
    }

    // Notify the parent component that the current dataMode changed.
    if (typeof onDataModeChange === 'function') {
      onDataModeChange(newValue);
    }
  };

  const [
    activeAnteriorityId,
    setActiveAnteriorityId,
  ] = React.useState(frontAnteriorityConfigs[0].id);

  const handleAnteriorityChange = (event, newValue) => {
    // when clicking on the current active button, newValue is set to null
    if (newValue != null) {
      setActiveAnteriorityId(newValue);
    }
  };

  // Update default mode when updating stations list only if :
  // - the data modes are changing
  // - and the current selected data mode is not present in the current list
  useEffect(() => {
    if (!selectedStationsModesIds.includes(activeDataModeId)) {
      setActiveDataModeId(selectedStationsModesIds[0]);
    }
  }, [selectedStationsModesIds, activeDataModeId]);

  return (
    <div id="compare-page" className={classes.mainPadding}>
      <Grid className="stations-compare-date-config" container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6} container>
          <Box className={classes.datePicker}>
            <CustomDatePicker label="Période se terminant le" />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} container>
          <StationDataToggleButtons
            activeDataModeId={activeDataModeId}
            handleDataModeChange={handleDataModeChange}
            activeAnteriorityId={activeAnteriorityId}
            handleAnteriorityChange={handleAnteriorityChange}
            availableDataModes={currentAvailableDataModeConfigs}
          />
        </Grid>
      </Grid>

      <Box className={`stations-compare-selected-stations ${classes.stdPadding}`}>
        <StationsListAutocomplete
          stationsProp={stationsProp}
          selectedStations={selectedStations}
          onStationChange={onStationChange}
        />
      </Box>

      {emptyStationsList && (
        <Paper className={classes.chartsWrapper}>
          <Typography>Pas de stations sélectionnées</Typography>
        </Paper>
      )}
      {!emptyStationsList && (
        <>
          <StationsCompareDetails selectedStations={selectedStations} />

          <StationChart
            className="stations-compare-chart"
            id={currentStationsCodes}
            mode={activeDataModeId}
            anteriorityId={activeAnteriorityId}
            endDate={selectedDate}
            isCompare
          />

          <StationsQmjDayChart
            stations={selectedStations}
            date={selectedDate}
          />
        </>
      )}
    </div>
  );
};

export default StationsCompare;
