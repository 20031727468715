import React from 'react';

import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import StationDashboardSummary from '../StationDashboardSummary';

import { colors } from '../../helpers/commonHelpers';

const StationsCompareDetails = ({ selectedStations = [], ...otherProperties }) => (

  <Accordion {...otherProperties}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>Détail des stations sélectionnées</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {selectedStations.map((station, index) => (
          <Grid item key={station.code}>
            <Box borderTop={3} borderColor={colors[index]}>
              <Card>
                <CardContent>
                  <StationDashboardSummary properties={station} key={station.code} />
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default StationsCompareDetails;
