import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';
import Layout from '../components/Layout';
import Link from '../components/Link';
import StationsCompare from '../components/StationsCompare/StationsCompare';
import PrintButton from '../components/PrintButton';

const ComparePage = ({ location: { state } }) => {
  const isLive = typeof window !== 'undefined';
  const selectedDate = useSelector(({ mainDate }) => mainDate);
  const [activeDataModeId, setActiveDataModeId] = React.useState(null);

  const onDataModeChange = dataMode => {
    setActiveDataModeId(dataMode);
  };

  if (!isLive) {
    return null;
  }

  const { stationProperties } = state;

  // Prepare file base name for export.
  const selectedDateObject = new Date(selectedDate);
  const fileBaseName = `comparaison_${activeDataModeId}_${selectedDateObject.toLocaleDateString()}`;

  return (
    <Layout noFooter title="Comparaisons">
      <Typography variant="body2" gutterBottom>
        <Link to="/">‹ Retour à la carte</Link>
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1">
            Comparaison
          </Typography>
        </Grid>
        <Grid item>
          <PrintButton
            elementConfigurations={[
              {
                targetQuerySelector: '#compare-page',
                scale: 1.5,
                onClone: clonedDocument => {
                  const inputElement = clonedDocument.querySelector('.stations-compare-selected-stations input');
                  if (inputElement !== null) {
                    inputElement.placeholder = '';
                  }
                  const deleteIcons = clonedDocument.querySelectorAll('.stations-compare-selected-stations svg.MuiChip-deleteIcon');
                  deleteIcons.forEach(element => {
                    element.remove();
                  });
                },
              },
            ]}
            fileBaseName={fileBaseName}
          />
          <Typography variant="caption">
            Exporter les graphiques
          </Typography>
        </Grid>
      </Grid>

      <StationsCompare
        defaultStationProperties={stationProperties}
        onDataModeChange={onDataModeChange}
      />
    </Layout>
  );
};

export default ComparePage;
