import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import { Bar } from 'react-chartjs-2';

import dayjs from 'dayjs';
import useStationData from '../../hooks/useStationData';
import { colors, lighterColors, isLive } from '../../helpers/commonHelpers';

const useStyles = makeStyles(theme => ({
  chartsWrapper: {
    padding: theme.spacing(1, 2, 2),
    marginTop: theme.spacing(1),
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

const options = {
  indexAxis: 'y',
  maintainAspectRatio: false,
  plugins: {
    legend: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const hoverColor = 'grey';

const reorderArray = (indexArray, array) => indexArray.map(i => array[i]);

const getOrderedColors = (colorArray, nbStations, indexArray) => {
  const subsetColors = colorArray.slice(0, nbStations);
  return reorderArray(indexArray, subsetColors);
};

// we want to order labels and data as per the station order
const qmjEmptyValue = [{ }];
const formatData = (stations = [], graphData = qmjEmptyValue) => {
  const nbStations = stations.length;

  const orders = stations.map(({ order }) => order);
  const indexArray = Array.from(Array(orders.length).keys())
    .sort((a, b) => orders[a] > orders[b]);

  const sortedColors = getOrderedColors(colors, nbStations, indexArray);
  const sortedLighterColors = getOrderedColors(lighterColors, nbStations, indexArray);

  const labels = reorderArray(indexArray, stations.map(({ label }) => label));
  const codes = reorderArray(indexArray, stations.map(({ code }) => code));
  // take out the date value in first position in the array
  const qmjValues = graphData[0] ?? null;
  const sortedData = codes.map(code => (qmjValues ? qmjValues.v?.[code] : null));

  return {
    labels,
    datasets: [
      {
        backgroundColor: sortedLighterColors,
        borderColor: sortedColors,
        borderWidth: 1,
        hoverBackgroundColor: hoverColor,
        hoverBorderColor: hoverColor,
        data: sortedData,
      },
    ],
  };
};

const StationsQmjDayChart = ({ stations = [], date, isCompare = true }) => {
  const classes = useStyles();

  const stationCodes = stations.map(({ code }) => code);

  const {
    isLoading,
    data: {
      values = [],
    } = {},
  } = useStationData(isCompare, stationCodes, 'QmJ', '1j', date);

  const formattedData = React.useMemo(
    () => formatData(stations, values),
    [stations, values],
  );

  const formattedDate = dayjs(date).format('DD/MM/YYYY');

  return (
    <Paper className={classes.chartsWrapper}>
      <Typography>QmJ du {formattedDate} (amont vers aval)</Typography>
      {isLoading && (
        <Box className={classes.spinner}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && isLive && (
        <Box>
          <Bar
            data={formattedData}
            options={options}
          />
        </Box>
      )}
    </Paper>
  );
};

export default React.memo(StationsQmjDayChart);
